.modal-overlay {
    position: fixed;
    top: 0;
    left: -4.5vw;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    margin-bottom: 100px;
  }
  
  .modal-open {
    opacity: 1;
    visibility: visible;
  }
  
  .modal-content {
    position: relative;
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    transform: scale(0.8);
    transition: transform 0.3s ease;
    border: 1px dashed gray;
    z-index: 9999;
    height: 80vh;
    overflow-y: auto;
    margin-bottom: 100px;
  }
  
  .modal-zoom-in {
    transform: scale(1);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }
  
  .modal-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .modal-close {
    font-size: 1.5rem;
    cursor: pointer;
    background: none;
    border: none;
  }
  
  .modal-body {
    margin-bottom: 1rem;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-close-button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-close-button:hover {
    background-color: #0056b3;
  }
  