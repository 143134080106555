/* src/components/Cursor.css */
.cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    background: red;
    border-radius: 50%;
    pointer-events: none; /* Prevent cursor from interfering with mouse events */
    transition: transform 0.1s ease;
    transform: scale(1);
  }
  
  /* Example animation */
  .cursor:hover {
    background: blue;
    transform: scale(1.5); /* Enlarges cursor on hover */
  }
  