@import "../../_globalColor";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

// .software-skill-inline > i {
//   color: $subTitle;
// }

// .software-skill-inline > i:hover {
//   color: $skillsColor;
// }
.software-skill-inline > p {
  color: $subTitle;
  font-size: 10px;
}
.software-skill-inline > i:hover ~ p {
  color: $skillsColor;
}

/* Animation for each skill item */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Base style for each skill item */
.software-skill-inline {
  opacity: 0; /* Start hidden */
  transform: translateY(20px); /* Start below the viewport */
  transition: opacity 0.6s ease, transform 0.6s ease;
}

/* Class added when the skill item is visible */
.software-skill-inline.visible {
  opacity: 1;
  transform: translateY(0);
}
