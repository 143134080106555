/* src/components/Firework.css */
.firework-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Prevent interference with other elements */
    overflow: hidden;
  }
  
  .firework {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: rgba(192, 238, 229, 0.9); /* Firework color */
    border-radius: 50%;
    animation: explosion 1s ease-out;
  }
  
  @keyframes explosion {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(5);
      opacity: 0;
    }
  }
  