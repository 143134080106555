.cards {
  background: rgba(169, 191, 192, 0.35);
  border-radius: 15px;
  box-shadow: 0px 10px 20px 20px rgba(0, 0, 0, 0.17);
  padding: 20px;
  -webkit-perspective: 1800px;
  perspective: 1800px;
  text-align: left;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(11deg) rotateY(16.5deg);
  transform: rotateX(11deg) rotateY(16.5deg);
  width: 100%;
  height: 260px;
  display: flex;
  /* flex-wrap: wrap; */
  gap: 15px;
  box-sizing: border-box;
}
.card {

  position: relative;
  flex: 1 1 calc(33.33% - 20px); /* Adjust based on the number of cards per row and gap */
  margin: 10px; /* Margin between cards */
  box-sizing: border-box; /* Include padding and border in width and height */
  display: flex; /* Use flexbox to align items */
  flex-direction: column; /* Ensure contents stack vertically */
}

.card__img {
  position: absolute;
  width: 80%; /* Scaled width */
  height: auto; /* Scaled height */
}

.card_one .card__img {
  top: 10px; /* Reduced top */
  right: -8px; /* Reduced right */
 
}

.card_two .card__img {
  top: 15px; /* Reduced top */
}

.card_three .card__img {
  top: 5px; /* Adjusted top */
  left: -3px; /* Reduced left */
 
}
.card__bg {
  background: url('../../assets/images/bg.png') center/cover no-repeat;
  width: 100%;
  height: 100%; /* Make sure it covers the card */
  position: relative; 
  top: 0;
  left: 0;
  border-radius: 3rem;
}

.sound {
  position: absolute; 
  top: 10px; 
  left: 10px; 
  z-index: 1000; 
  color: rgb(255, 213, 0);
  font-weight: 700;
  font-size: 30px; 
  opacity: 0; 

}

.card:hover .sound {
  opacity: 1; 
}

.card__img {
  position: absolute;
  width: 100%; 
  height: auto; 
  top: 0; 
  left: 0; 
  object-fit: cover; 
}


.card_one .card__img {
  top: 10px;
  right: -8px;
}

.card_two .card__img {
  top: 15px;
}

.card_three .card__img {
  top: 5px;
  left: -3px;
}

.card_one .card__bg, 
.card_two .card__bg, 
.card_three .card__bg {
  width: 100%;
  height: 200px; /* Make sure background fills the card */
}
