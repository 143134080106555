/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
}

.container {
  width: 90vw; /* Adjust width as needed */
  margin: 0 auto;
}

.timeline {
  position: relative;
  padding: 20px 0;
  margin: 0 auto;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 100%;
  background: #333;
}

.box {
  position: relative;
  width: 45%;
  padding: 20px;
  margin: 20px 0;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.box:nth-child(odd) {
  margin-left: 53%; /* Space for the date on the left side */
}

.box:nth-child(even) {
  margin-right: 30%; /* Space for the date on the right side */
}

.box::before {
  content: '';
  position: absolute;
  top: 20px;
  width: 20px;
  height: 20px;
  background: #6493a9;
  border-radius: 50%;
  z-index: 1;
}

.box:nth-child(odd)::before {
  left: 10px;
  
}

.box:nth-child(even)::before {
  right: 10px;
}

.date {
  position: absolute;
  top: 20px;
  width: 30vw;
  color: white;
  background: #000000;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: bold;
  z-index: 2; /* Ensure it is above other content */
}

.box:nth-child(odd) .date {
  right: 42vw; /* Adjust space to ensure visibility */
}

.box:nth-child(even) .date {
  left: 42vw; /* Adjust space to ensure visibility */
}

.imgBx {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgBx img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  padding: 20px;
}

.content h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.content p {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.box {
  animation: fadeIn 1s ease-in-out;
}
.box {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.box:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

