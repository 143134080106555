@import "../../_globalColor";

.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}

.greeting-main {
  display: flex;
}
.greeting-text-div{
  height: 90vh;
}
.subTitle {
  color: $subTitle !important;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}
.button-greeting-div {
  display: flex;
  margin-top: 20px;
  
}
.download-link-button {
  text-decoration: none;
}

.greeting-text {
  font-size: 70px;
  line-height: 1.1;
  color: $textColor !important;
}

.greeting-text-p {
  font-size: 30px;
  line-height: 40px;
}

.greeting-image-div  {
  display: flex;
  justify-content: center;
  align-items: center;
  
 
}
.greeting-image-div > img {
  max-width: 100%;
 
  height:70vh;
  border-radius:2rem;
}

.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
}

#main {
  -moz-transition: -moz-filter 0.5s ease, -webkit-filter 0.5s ease,
    -ms-filter 0.5s ease, -moz-filter 0.5s ease;
  -webkit-transition: -moz-filter 0.5s ease, -webkit-filter 0.5s ease,
    -ms-filter 0.5s ease, -webkit-filter 0.5s ease;
  -ms-transition: -moz-filter 0.5s ease, -webkit-filter 0.5s ease,
    -ms-filter 0.5s ease, -ms-filter 0.5s ease;
  transition: -moz-filter 0.5s ease, -webkit-filter 0.5s ease,
    -ms-filter 0.5s ease, filter 0.5s ease;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

#main .thumb {
  -moz-transition: opacity 1.25s ease-in-out;
  -webkit-transition: opacity 1.25s ease-in-out;
  -ms-transition: opacity 1.25s ease-in-out;
  transition: opacity 1.25s ease-in-out;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  opacity: 1;
  overflow: hidden;
  position: relative;
}

#main .thumb:after {
  background-image: -moz-linear-gradient(
    to top,
    rgba(10, 17, 25, 0.35) 5%,
    rgba(10, 17, 25, 0) 35%
  );
  background-image: -webkit-linear-gradient(
    to top,
    rgba(10, 17, 25, 0.35) 5%,
    rgba(10, 17, 25, 0) 35%
  );
  background-image: -ms-linear-gradient(
    to top,
    rgba(10, 17, 25, 0.35) 5%,
    rgba(10, 17, 25, 0) 35%
  );
  background-image: linear-gradient(
    to top,
    rgba(10, 17, 25, 0.35) 5%,
    rgba(10, 17, 25, 0) 35%
  );
  pointer-events: none;
  background-size: cover;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

#main .thumb > .image {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

#main .thumb > h2 {
  pointer-events: none;
  bottom: 1.875em;
  font-size: 0.8em;
  left: 2.1875em;
  margin: 0;
  position: absolute;
  z-index: 1;
}

#main .thumb > p {
  display: none;
}

#main:after {
  pointer-events: none;
  -moz-transition: opacity 0.5s ease, visibility 0.5s;
  -webkit-transition: opacity 0.5s ease, visibility 0.5s;
  -ms-transition: opacity 0.5s ease, visibility 0.5s;
  transition: opacity 0.5s ease, visibility 0.5s;
  background: rgba(36, 38, 41, 0.25);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}

body.ie #main:after {
  background: rgba(36, 38, 41, 0.55);
}

body.content-active #main {
  -moz-filter: blur(6px);
  -webkit-filter: blur(6px);
  -ms-filter: blur(6px);
  filter: blur(6px);
}

body.content-active #main:after {
  pointer-events: auto;
  opacity: 1;
  visibility: visible;
}

body.is-preload #main .thumb {
  pointer-events: none;
  opacity: 0;
}

#main .thumb {
  -moz-transition-delay: 2.525s;
  -webkit-transition-delay: 2.525s;
  -ms-transition-delay: 2.525s;
  transition-delay: 2.525s;
  height: calc(40vh - 2em);
  min-height: 20em;
  width: 25%;
}

#main .thumb:nth-child(1) {
  -moz-transition-delay: 0.65s;
  -webkit-transition-delay: 0.65s;
  -ms-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
    z-index: 999;
  }
  .greeting-text {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }
  .greeting-image-div {
    height: 256px;
  }
  .greeting-image-div{
   height: 800px;
  }
  .greeting-image-div img{
    height: auto;
    border-radius: 2rem;
    width: auto;

  }
}

@media (max-width: 320px) {
  .main {
    width: auto;
  }
}

.typed-cursor{
  display: none;
}